/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/*!* Core CSS required for Ionic components to work properly *!
@import "~@ionic/angular/css/core.css";

!* Basic CSS for apps built with Ionic *!
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

!* Optional CSS utils that can be commented out *!
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.button-blueocean {
  --background: #07009B;
  color: white;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: -0.2px;
  word-spacing: 2px;
  font-size: small;
  font-weight: bold;
}

.background-general {
  --background: rgb(236, 236, 236);
  // --background-color: blue;
}

.background-clear {
  --background: rgba(236, 236, 236, 0);

}

.class-component-notification {
  --height: 300px;
  --width: 350px;
  --max-height: 300px;
  --backdrop-opacity: 0;

  &::part(backdrop) {

  }

  &::part(arrow) {

  }

  &::part(content) {

  }
}*/

.modal-orders {
  --max-height: 375px;
}

.modal-orders-add-product {
  --height: 60%;
  --width: 90%;

  &::part(content) {
    --height: 90%;

  }
}

.modal-delete-product {
  --max-height: 200px;
  --width: 25%;

  &::part(content) {
    --height: 90%;
  }
}

.modal-add-category {
  --height: 85%;
  --width: 90%;
}

.modal-add-holiday {
  --max-height: 85%;
  --max-width: 90%;
  --width: 75%;
  --height: 75%;
}

.bg-whitesmoke {
  background-color: whitesmoke !important;
}

.class-component-account {
  --width: 350px;
  --backdrop-opacity: 0;

  &::part(backdrop) {

  }

  &::part(arrow) {

  }

  &::part(content) {

  }
}

/*.no-padding-grid {
  margin: 0px;
  --ion-grid-padding: 0px;
  --ion-padding: 0px;
  padding: 0px;
}

.no-padding-item {
  --padding-start: 0px;
}

.no-padding {
  padding: 0px;
  --inner-padding-bottom: 0px;
  --inner-padding-end: 0px;
  --inner-padding-start: 0px;
  --inner-padding-top: 0px;
  --padding-bottom: 0px;
  --padding-end: 0px;
  --padding-start: 0px;
  --padding-top: 0px;
}

.popover-wide {
  max-height: 30% !important;
}

.ion-color-ianos {
  --ion-color-base: var(--ion-color-ianos);
  --ion-color-base-rgb: var(--ion-color-ianos-rgb);
  --ion-color-contrast: var(--ion-color-ianos-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ianos-contrast-rgb);
  --ion-color-shade: var(--ion-color-ianos-shade);
  --ion-color-tint: var(--ion-color-ianos-tint);
}

.ion-color-ianos-second {
  --ion-color-base: var(--ion-color-ianos-second);
  --ion-color-base-rgb: var(--ion-color-ianos-second-rgb);
  --ion-color-contrast: var(--ion-color-ianos-second-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ianos-second-contrast-rgb);
  --ion-color-shade: var(--ion-color-ianos-second-shade);
  --ion-color-tint: var(--ion-color-ianos-second-tint);
}

.ion-color-transparent {
  --ion-color-base: var(--ion-color-transparent);
  --ion-color-base-rgb: var(--ion-color-transparent-rgb);
  --ion-color-contrast: var(--ion-color-transparent-contrast);
  --ion-color-contrast-rgb: var(--ion-color-transparent-contrast-rgb);
  --ion-color-shade: var(--ion-color-transparent-shade);
  --ion-color-tint: var(--ion-color-transparent-tint);
}

.heartbeat {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

!**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 *!
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}*/

//ADDED BY LEO 07-10-2022
.main {
  background-color: whitesmoke;
}

// ADDED BY LEO 7-10-2022
.ion-page {
  overflow-y: auto !important;
}

#menu-content {
  overflow-y: auto !important;
}

.searchbar-input {
  box-shadow: none !important;
  //border-radius: 50rem !important;
  border: 1px solid lightblue !important;
  max-height: 28px;
}

.nav-pills-falcon .btn, .nav-pills-falcon .tox .tox-menu__footer .tox-button--secondary, .tox .tox-menu__footer .nav-pills-falcon .tox-button--secondary, .nav-pills-falcon .tox .tox-dialog__footer .tox-button--secondary, .tox .tox-dialog__footer .nav-pills-falcon .tox-button--secondary, .nav-pills-falcon .tox .tox-menu__footer .tox-button:last-child, .tox .tox-menu__footer .nav-pills-falcon .tox-button:last-child, .nav-pills-falcon .tox .tox-dialog__footer .tox-button:last-child, .tox .tox-dialog__footer .nav-pills-falcon .tox-button:last-child, .nav-pills-falcon [data-list] .page, [data-list] .nav-pills-falcon .page {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
  border-radius: 50rem;
  color: var(--falcon-600);
  padding: 0.5rem 1rem;
}

.nav-pills-falcon {
  background-color: transparent;
  border-radius: .25rem !important;
  border-color: transparent;
}

.ngx-pagination li {
  display: inline-block;
  margin: 0.25em;
}

.nav-pills-falcon .btn.active, .nav-pills-falcon .tox .tox-menu__footer .active.tox-button--secondary, .tox .tox-menu__footer .nav-pills-falcon .active.tox-button--secondary, .nav-pills-falcon .tox .tox-dialog__footer .active.tox-button--secondary, .tox .tox-dialog__footer .nav-pills-falcon .active.tox-button--secondary, .nav-pills-falcon .tox .tox-menu__footer .active.tox-button:last-child, .tox .tox-menu__footer .nav-pills-falcon .active.tox-button:last-child, .nav-pills-falcon .tox .tox-dialog__footer .active.tox-button:last-child, .tox .tox-dialog__footer .nav-pills-falcon .active.tox-button:last-child, .nav-pills-falcon [data-list] .active.page, [data-list] .nav-pills-falcon .active.page {
  background-color: #D5E5FA !IMPORTANT;
}

.container-fluid {
  padding-right: 2px;
}

.modal-wrapper {
  background-color: whitesmoke !important;
}

.card-content-md {
  background-color: whitesmoke !important;
  padding: 0 !important;
}

// ADDED BY LEO 15-10-2022
.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  z-index: 100000;
}

.new-switch {
  width: 2.7rem;
  background-image: none;
  border: none;
  height: 1.7em;
}

// ADDED BY LEO 17-10-2022
ion-select {
  max-height: 36px !important;
  margin-top: 0;
}

ion-input {
  max-height: 36px !important;
  padding: 6px;
  margin-top: 0;
}

.form-label {
  text-transform: capitalize;
}

// ADDED BY LEO 19-10-2022
.searchbar-search-icon.sc-ion-searchbar-md {
  left: 16px;
  top: 5px;
  width: 1em;
  height: 1em;
}

// ADDED BY LEO 20-10-2022
.ngx-pagination {
  zoom: 0.8;
}

ion-toggle {
  zoom: 0.8;
}

.form-select-sm {
  background: white;
  color: black;
}

.avatar-5xl {
  height: 14.5rem;
  width: 14.5rem;
}

.fw-600 {
  font-weight: 600 !important;
}

/*ADDED BY LEO 22-10-2022*/
.background-general {
  --background: transparent !important;
}

.d-contents {
  display: contents;
}

.btn-secondary-light:disabled, .btn-secondary-light.disabled {
  color: black;
  background-color: lightgray;
  border-color: lightgray;
}

#googleMap {
  // margin-top: 30px;
  width: 100%;
  height: 300px;
}

.accordion-button::after {
  margin-right: 1rem;
}

.container-fluid {
  padding-left: 0.5em !important;
  padding-right: 0.5em !important;
}

ion-accordion-group {
  ion-padding {
    background-color: #d2dceb;
  }

  ion-accordion {
    background-color: #d2dceb;
  }
}

.time {
  width: min-content;
  height: fit-content;
  background-color: gray;
  color: white;
  border: none;
  max-width: fit-content;
}

@media (min-width: 1200px) {
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl + .content {
    margin-right: 0 !important;
  }

  .navbar-vertical.navbar-expand-xl + .content {
    margin-right: 0 !important;
  }

  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl + .content .navbar-top {
    padding-right: 2rem !important;
  }
}

@media screen and (max-width: 768px) {
  .card-body {
    padding: 0.3em;
  }
}

@media screen and (max-width: 768px) and (min-width: 576px) {
  .w-md-20 {
    width: 20% !important;
  }
}

@media screen and (max-width: 575px) {
  .w-md-20 {
    width: auto !important;
  }
}

/**
 * ----------------------------------------
 * animation scale-in-top
 * ----------------------------------------
 */

 .scale-in-top {
	-webkit-animation: scale-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-in-top {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-top {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
}
